import React from "react";
//import { useQuery, gql } from '@apollo/client'
import "../pages/css/pages.css";
import useFetch from "../hooks/useFetch";
import "./css/BannerPagina.css"
//Test

const { REACT_APP_CMS_URL } = process.env;

const BannerPagina = ({ pagina, marginbottom = true }) => {
  pagina = pagina.toUpperCase();
  const {
    dataCms: dataBanner,
    errorCms: errorBanner,
    loadingCms: loadingBanner,
  } = useFetch(REACT_APP_CMS_URL + "banner/" + pagina, "CMS", "");
  //loadingCms: loadingBanner } = useFetch(REACT_APP_CMS_URL + 'banner-paginas', 'CMS', "?populate=*")

  //const { loading: loadingBanner, error: errorBanner, data: dataBanner } = useQuery(Banner)

  if (loadingBanner)
    return (
      <div className="row text-center">
        <h3>Loading ....</h3>
      </div>
    );

  if (errorBanner)
    return (
      <div className="row text-center">
        Lo sentimos. Hay un problema cargando la pagina. Comuníquese con su
        administrador del sistema
      </div>
    );

  let objBanner = dataBanner.find((x) => x.Page === pagina);

  let marginbottomClass = "row p-0 mb-5 mx-0";
  if (marginbottom === false) marginbottomClass = "row p-0 mx-0";

  let alineacionTexto = "righttextbanner";
  if (objBanner.Alignment.toUpperCase() === "LEFT")
    alineacionTexto = "lefttextbanner";

  return (
    <>
      {!loadingBanner && (
        <>
          <div className={marginbottomClass}>
            <div
              className="p-0 m-0 bannerPage"
              style={{
                backgroundImage: `url(${objBanner.Banner})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "15rem",
              }}
            >
              <div className={alineacionTexto}>
                <div className={`${alineacionTexto}-text`}>
                  <h1 className="card-title">{objBanner.Title}</h1>
                  <hr className="w-50 hrblue" />
                  <div className="subtitle-banner">
                    <span>{objBanner.Subtitle}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* {
        (!loadingBanner) ?
          (
            <div className={marginbottomClass}>
              <div className=" p-0 m-0 bannerpage">
                <img src={objBanner.Banner}
                  className="card-img" alt="mediospago" />
                <div className={alineacionTexto}>
                  <h1 className="card-title mx-5 mt-5">{objBanner.Title}</h1>
                  <hr className='hrblue' />
                  <div className='col-md-8'>
                    <h4 className=" mx-5 ">{objBanner.Subtitle}</h4>
                  </div>
                  <div className='col-md-4'></div>
                </div>
              </div>
            </div>

          )
          : (<></>)
      } */}
    </>
  );
};

export default BannerPagina;
