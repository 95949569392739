import {
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";




export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({
            "username": process.env.REACT_APP_SECURITY_USER,
            "password": process.env.REACT_APP_SECURITY_PASSWORD
        });
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_URL_SECURITY_SERVICE}`, requestOptions)
            .then(async (res) => {
                const response = await res.json();
                localStorage.setItem("token", response.data.token);
                setToken(response.data.token)
            })
            .catch((err) => {
                console.log(err);
                setToken(null);
                localStorage.removeItem("token");
            })
    }, [])


    return (
        <AuthContext.Provider value={{ token, setToken }}>
            {children}
        </AuthContext.Provider>
    );
};

export function useAuthContext() {
    const context = useContext(AuthContext);
    if (!context) {
        console.log("Error al utilizar authContext");
    }
    return context;
}

export default AuthProvider;
