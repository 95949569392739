import React from 'react'
import Fade from 'react-reveal/Fade';

const DivPaymentAccount = ({ imageUrl, bankName, payTo, accountType, account, agreement }) => {

    return (
        <>
            <Fade top>
                <div className="col-md-4 my-5 px-4 justify-content-center text-center ">
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <img src={imageUrl} className="img-fluid" alt='' />
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                    <div className='row text-center'><h5><strong>{bankName}</strong></h5></div>
                    <hr className='hrblue'/>
                    <div className='row text-center'><small>A nombre de {payTo}</small></div>
                    <div className='row text-center'><small>Tipo de Cuenta: {accountType}</small></div>
                    <div className='row text-center'><small>Cuenta: {account}</small></div>
                    {agreement ? (
                        <div className='row'><small>Convenio: {agreement}</small></div>
                    ) :
                        (<></>)}
                </div>
            </Fade>
        </>
    )
}

export default DivPaymentAccount