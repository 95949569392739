import React from 'react';
import Flip from 'react-reveal/Flip';
import { useResolution } from '../hooks/useResolution';

const CardAnimatedCobranza = ({ title, subtitle, buttonText, buttonUrl, imageUrl, columns = 4, fixedHeight = 80 }) => {

    const { resolution } = useResolution();

    let classColumns = "col-md-3 gx-5 text-center";
    if (columns === 3)
        classColumns = "col-md-4 gx-5 text-center";

    if (columns === 2)
        classColumns = "col-md-5 gx-4 text-center";

    if (columns === 5)
        classColumns = "col-md-2 text-center";

    let styleHeight = { height: fixedHeight };
    if (fixedHeight === 0)
        styleHeight = {};

    if (resolution > 425) {
        return (
            <>
                <Flip top>
                    <div className="row" style={{ height: "18rem" }}>
                        <div
                            className="col-md-6"
                            style={{
                                backgroundImage: `url(${imageUrl})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                            }}
                        ></div>
                        <div
                            className="col-md-6 text-center"
                            style={{ backgroundColor: "#FCFCFC" }}
                        >
                            {/* <h3 className="card-title py-2">Para personas</h3> */}
                            <h1 className="card-title my-4">{title}</h1>
                            <p className="card-text my-4">
                                {subtitle}
                            </p>
                            <br />
                            <a href="/contactenos" className="btn btn-outline-secondary">
                                {buttonText}
                            </a>
                        </div>
                    </div>

                </Flip>
            </>
        )
    } else {
        return (
            <>
                <Flip top>
                    <div className="justify-content-center" style={{ height: "auto" }}>
                        <div
                            className="col-md-6"
                            style={{
                                backgroundImage: `url(${imageUrl})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                height: "20rem"
                            }}
                        ></div>
                        <div
                            className="col-md-6 text-center"
                            style={{ backgroundColor: "#FCFCFC" }}
                        >
                            {/* <h3 className="card-title py-2">Para personas</h3> */}
                            <h1 className="card-title my-4">{title}</h1>
                            <p className="card-text my-4">
                                {subtitle}
                            </p>
                            <br />
                            <a href="/contactenos" className="btn btn-outline-secondary">
                                {buttonText}
                            </a>
                        </div>
                    </div>

                </Flip>
            </>
        )
    }

}

export default CardAnimatedCobranza