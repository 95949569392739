import React, { useEffect, useState } from 'react'
import DivPaymentAccount from '../components/DivPaymentAccount'
import logopse from '../components/img/pse1.png';
import './css/pages.css'
import BannerPagina from '../components/BannerPagina'

const MediosPago = () => {

  const [errorAccounts, setErrorAccounts] = useState(false);
  const [dataAccounts, setDataAccounts] = useState([]);
  const [loadingAccounts, setLoadingAccounts] = useState(false);

  useEffect(() => {
    setLoadingAccounts(true);
    fetch(`${process.env.REACT_APP_CMS_URL}accounttopay`, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } })
      .then(async (res) => {
        const data = await res.json()
        setDataAccounts(data)
        setLoadingAccounts(false);
      }).catch((err) => {
        setDataAccounts(null)
        setLoadingAccounts(false);
        setErrorAccounts(true);
      })
  }, [])

  if (loadingAccounts)
    return <div className='row text-center'><h3>Loading ....</h3></div>

  if (errorAccounts)
    return (
      <div className='row text-center'>
        Lo sentimos. Hay un problema cargando la pagina. Comuniquese con su administrador del sistema
      </div>)

  return (
    <div className='row mb-5'>
      <BannerPagina pagina="MEDIOS_PAGO" />
      <div className="container m-2">
        <div className="row">
          <div className="text-center">
            <div className="row"></div>
            <br />
            <div className="row p-4">
              <h3>Pague su microcrédito aquí</h3>
            </div>
            <div className="row">
              <a className="navbar-brand" href="https://sadminweb.sadmin.net/PayValida.aspx?id9MC/7TNSlVbIa4aSWV4A==">
                <img src={logopse} width="90" height="90" className="d-inline-block align-top py-0" alt="" />
              </a>
            </div>
            <hr className="hrblue" />
          </div>
          <div className="text-center mt-5">
            <h3>Entidades Bancarias</h3>
            {/* <hr className='hrblue' /> */}
            <div className="row mt-2">

              {!loadingAccounts ?
                (
                  dataAccounts.map(account => {
                    return (
                      <DivPaymentAccount
                        key={account.Id}
                        imageUrl={account.BankLogo}
                        bankName={account.BankName}
                        payTo={account.PayTo}
                        accountType={account.AccountType}
                        account={account.AccountNumber}
                        agreement={account.Convenio}
                      />
                    )
                  })
                ) :
                (
                  <></>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <div className='row text-center mt-5'>
        <p className='mx-2'>NOTA: Los pagos son efectuados ÚNICAMENTE en cuentas bancarias a
          nombre de Renovar Financiera. Cualquier irregularidad, favor comunicarse al PBX:
          (+57)(601)7563468 y/o al
          email: servicioalcliente@renovarfinanciera.com
        </p>
      </div>
    </div>
  )
}

export default MediosPago