import BannerPagina from '../components/BannerPagina'
import CarRequirements from '../components/CardRequirements'
import WhatsappButton from '../components/WhatsappButton'

// images
import imgDocente from './img/docente.jpg'
import imgPensionados from "./img/pensionados.jpg"
import imgEmpleadoPublico from "./img/empleado_publico.jpg"
import imgMilitar from "./img/militar.jpg"
import imgPolicia from "./img/policia.jpg"
import imgLogoAvvillas from './img/logo_avvillas.jpg'
import imgLogoBancoBogota from './img/logo_banco_bogota_2.png'
import imgLogoBancoW from './img/logo_bancow.jpg'
import imgLogoBancoUnion from './img/logo_bancounion.png'


const Libranza = () => {
  
  return (
    <>
      <BannerPagina pagina="Libranza" />
      <div className="container">
        <div className="row text-center">
          <h2>Requisitos</h2>
          <hr className='hrblue' />
        </div>
        <div className="row justify-content-md-center">
          <CarRequirements
            title="DOCENTES"
            subtitle={`Tipo de contrato propiedad o provisional.
            -Edad máxima 71 años.
            -Antigüedad mínima 1 año.
            -Plazo  máximo 108 meses.
            -No reportes activos o contar con paz y salvo.
            -Aplica compras de cartera y libre inversión.
            -Monto a prestar según capacidad de desprendible.
            `}
            buttonText="Solicita tu crédito"
            buttonUrl="/otrosproductos"
            imageUrl={imgDocente}
          />
          <CarRequirements
            title="PENSIONADOS"
            subtitle={`Edad máxima 70 años.
            -No reportes activos o contar con paz y salvo.
            -Plazo máximo 120 meses.
            -Aplica Compras de cartera y libre inversión.
            -No tener la pensión compartida.
            -Monto a prestar según capacidad de desprendible.            
            `}
            buttonText="Solicita tu crédito"
            buttonUrl="/otrosproductos"
            imageUrl={imgPensionados}
          />
          <CarRequirements
            title="SERVIDORES PÚBLICOS"
            subtitle={`Tipo de contrato propiedad o provisional.
            -Edad máxima 70 años.
            -Antigüedad desde el 1 año.
            -Plazo máximo 108 meses.
            -No reportes activos o contar con paz y salvo.
            -Aplica Compras de cartera, libre inversión y retanqueo.
            -Monto a prestar según capacidad de desprendible.                        
            `}
            buttonText="Solicita tu crédito"
            buttonUrl="/otrosproductos"
            imageUrl={imgEmpleadoPublico}
          />
        </div>
        <div className="row justify-content-md-center">
          <CarRequirements
            title="FUERZAS MILITARES"
            subtitle={`Personas activas en las Fuerzas Militares.
            -Antigüedad desde los 6 meses en adelante.  
            -Tiempo máximo 120 meses.
            -No reportes activos o contar con paz y salvo.
            -Aplica Compras de cartera y libre inversión  y retanqueo.
            -Monto a prestar según capacidad de desprendible.                               
            `}
            buttonText="Solicita tu crédito"
            buttonUrl="/otrosproductos"
            imageUrl={imgMilitar}
          />
          <CarRequirements
            title="POLICÍA NACIONAL"
            subtitle={`Crédito digital desembolso en 24 horas para créditos nuevos. 
            -Retanqueo hasta 75 millones.                              
            `}
            buttonText="Solicita tu crédito"
            buttonUrl="/otrosproductos"
            imageUrl={imgPolicia}
          />
        </div>
        <div className="row text-center justify-content-md-center mt-5">
          <h1> Bancos Aliados</h1>
          <hr className='hrblue'/>
          <div className="col-md-2">
            <img src={imgLogoAvvillas} className="img-fluid" alt="" />
          </div>
          <div className="col-md-2">
            <img src={imgLogoBancoBogota} className="img-fluid" alt="" />
          </div>
          <div className="col-md-2">
            <img src={imgLogoBancoW} className="img-fluid" alt="" />
          </div>
          <div className="col-md-2">
            <img src={imgLogoBancoUnion} className="img-fluid" alt="" />
          </div>
        </div>
        <WhatsappButton/>

        
      </div>
    </>
  )
}

export default Libranza