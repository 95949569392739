import React, { useState } from 'react'
import Flip from 'react-reveal/Flip';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


const CarRequirements = ({ title, subtitle, buttonText, buttonUrl, imageUrl, columns = 4 }) => {
    let classColumns = "col-md-3 gx-5 text-center";
    if (columns === 3)
        classColumns = "col-md-4 gx-5 text-center"

    if (columns === 2)
        classColumns = "col-md-5 gx-4 text-center"

    if (columns === 5)
        classColumns = "col-md-2 text-center"

    // converts to array the subtitle
    let listString = subtitle.split('-')

    const [show, setShow] = useState(false);
    //const [modalTitle, setmodalTitle] = useState("Solicita tu Crédito")
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        //setmodalTitle(title);
    }

    return (
        <>
            <Flip top>
                <div className={classColumns}>
                    <div className="card shadow my-3 " >
                        <img src={imageUrl} className="card-img-top" alt={title} />
                        <div className="card-body" style={{ height: 290 }}>
                            <h5 className="card-title"><strong>{title}</strong></h5>
                            <hr className='hrblue' />
                            <div className='row' style={{ fontSize: 12 }}>
                                <ul className='list-group list-group-flush m-0 p-0'>
                                    {listString.map((item, index) => (
                                        <li key={index} className='list-group-item m-0 p-0'>{item}</li>
                                    )
                                    )}
                                </ul>

                            </div>
                        </div>
                        <div className="card-footer">
                            {/* <Button variant="secondary" onClick={() => handleShow("Solicita tu Paz y Salvo")}>
                                {buttonText}
                            </Button> */}
                            <Button variant="secondary">
                                <a href='https://web.whatsapp.com/send?phone=+573138946373' className='text-white' style={{textDecoration: "none"}}>{buttonText}</a>
                            </Button>
                        </div>
                    </div>

                </div>

            </Flip>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton >
                    <Modal.Title>Solicita tu Crédito</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <iframe
                        title='Solicitud de credito'
                        className="embed-responsive-item"
                        width={480}
                        height={810}
                        src="https://share.hsforms.com/18aON9Vn4SEaIH4dMVWvJzg4lyfd"
                        allow='allow-scripts allow-modal'
                    >
                    </iframe>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CarRequirements;