import React from "react";
import logoBlanco from "./img/logoRenovarBlanco1.webp";
import logoColab1 from "./img/LogoColab1.webp";
import logoIQNET from "./img/Logo-IQNet.webp";
import logoIcontex from "./img/Sello-ICONTEC_ISO-9001.webp";
import "./css/PiePagina.css"

const PiePagina = () => {
  return (
    <div
      expand="lg"
      variant="light"
      style={{ backgroundColor: "#002e5d" }}
      className="mt-5 pb-4"
    >
      <div className="container text-white">
        <div className="row justify-content-center">
          <div className="col-5  ">
            <img
              src={logoBlanco}
              className="d-inline-block align-top logo"
              alt=""
            />
            <h5> Nuestras oficinas</h5>
            <br></br>
            <label className="fw-bolder">Bogotá D.C, Colombia</label>
            <br />
            <label>
              Calle 35 # 7 - 25, Piso 3, Edificio Caxdac.
            </label> <br /> <br />
            <label className="fw-bolder">Santiago de Cali, Colombia</label>
            <br />
            <label>
              Carrera 4 # 8-39, Oficina 1001, Edificio Benjamín Herrera.
            </label>
            <br />
          </div>
          <div className="col-5 text-center pt-5">
            <h5> Certificados por:</h5>
            <div className="container_logos">
              <div>
                <div>
                  <img
                    src={logoIcontex}
                    className="d-inline-block align-top m-4"
                    alt=""
                  />
                </div>
                <div className="fw-bold">
                  SC-CER720213
                </div>
              </div>
              <div>
                <img
                  src={logoIQNET}
                  className="d-inline-block align-top m-4"
                  alt=""
                />
              </div>
            </div>

            <h5> Asociados a:</h5>
            <a href="https://www.colcob.com/">
              <img
                src={logoColab1}
                className="d-inline-block align-top m-4"
                alt=""
              />
            </a>
          </div>
          <div className="col-6 text-center">
            <div className="d-flex justify-content-evenly mt-3 align-items-center">
              <div>
                <a href="https://www.facebook.com/RenovarFinancieraCol">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={41}
                    height={41}
                    fill="none"
                  >
                    <path
                      fill="#FFF"
                      d="M40.077 28.406c0 7.28-4.34 11.62-11.62 11.62h-2.38c-1.1 0-2-.9-2-2v-11.54c0-.54.44-1 .98-1l3.52-.06c.28-.02.52-.22.58-.5l.7-3.82c.06-.36-.22-.7-.6-.7l-4.26.06c-.56 0-1-.44-1.02-.98l-.08-4.9c0-.32.26-.6.6-.6l4.8-.08c.34 0 .6-.26.6-.6l-.08-4.8c0-.34-.26-.6-.6-.6l-5.4.08a5.993 5.993 0 0 0-5.9 6.1l.1 5.5c.02.56-.42 1-.98 1.02l-2.4.04c-.34 0-.6.26-.6.6l.06 3.8c0 .34.26.6.6.6l2.4-.04c.56 0 1 .44 1.02.98l.18 11.4c.02 1.12-.88 2.04-2 2.04h-4.6c-7.28 0-11.62-4.34-11.62-11.64v-16.74c0-7.28 4.34-11.62 11.62-11.62h16.76c7.28 0 11.62 4.34 11.62 11.62v16.76Z"
                    />
                  </svg>
                </a>
              </div>
              <div>
                <a href="https://www.instagram.com/renovarfinanciera/?hl=es-la">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={41}
                    height={41}
                    fill="none"
                  >
                    <path
                      fill="#FFF"
                      d="M28.471.026H11.703C4.419.026.077 4.368.077 11.65V28.4c0 7.303 4.342 11.646 11.626 11.646H28.45c7.284 0 11.626-4.343 11.626-11.626V11.65C40.097 4.368 35.755.026 28.471.026Zm-8.384 27.773c-4.282 0-7.764-3.481-7.764-7.763 0-4.283 3.482-7.764 7.764-7.764s7.764 3.482 7.764 7.764-3.482 7.763-7.764 7.763ZM31.933 9.79c-.1.24-.24.46-.42.66-.2.18-.42.321-.66.421a1.98 1.98 0 0 1-2.181-.42c-.18-.2-.32-.42-.42-.66-.101-.24-.161-.5-.161-.76 0-.261.06-.521.16-.761.1-.26.24-.46.42-.66.46-.46 1.16-.68 1.801-.54.14.02.26.06.38.12.12.04.24.1.36.18.1.06.2.16.3.24.18.2.32.4.42.66.1.24.161.5.161.76s-.06.52-.16.76Z"
                    />
                  </svg>
                </a>
              </div>
              {/* <div>
                <a href="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={41}
                    height={33}
                    fill="none"
                  >
                    <path
                      fill="#FFF"
                      d="M30.077.026h-20c-6 0-10 4-10 10v12c0 6 4 10 10 10h20c6 0 10-4 10-10v-12c0-6-4-10-10-10Zm-6.22 18.06-4.94 2.96c-2 1.2-3.64.28-3.64-2.06v-5.94c0-2.34 1.64-3.26 3.64-2.06l4.94 2.96c1.9 1.16 1.9 3 0 4.14Z"
                    />
                  </svg>
                </a>
              </div> */}
            </div>
            <hr></hr>
            <a href="../politica_datos.pdf" target="_blank" rel="noreferrer" className="text-white">Política de tratamiento y protección de datos personales</a>
            <br />
            <a href="./contactenos" className="text-white">P.Q.R.S</a>
            <br />
            <label className="fw-bolder">Teléfono: </label>{" "}
            <label>(+57)(601) 756 3468</label>
            <br />
            <label className="fw-bolder">Whatsapp Business: </label>{" "}
            <label>(+57) 3138946373</label>
            <br />
            <label className="fw-bolder">E-mail: </label>{" "}
            <label> servicioalcliente@renovarfinanciera.com</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PiePagina;
