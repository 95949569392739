import React, { useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';

const PreguntasFrecuentes = () => {

  const [loadingFaq, setLoadingFaq] = useState(false);
  const [errorFaq, setErrorFaq] = useState(false);
  const [dataFaq, setDataFaq] = useState([]);

  useEffect(() => {
    setLoadingFaq(true);
    fetch(`${process.env.REACT_APP_CMS_URL}faq`, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } })
      .then(async (res) => {
        const data = await res.json()
        setDataFaq(data)
        setLoadingFaq(false);
      }).catch((err) => {
        setErrorFaq(true);
        setDataFaq(null)
        setLoadingFaq(false);
      })
  }, [])



  if (loadingFaq)
    return <div className='row text-center'><h3>Loading ....</h3></div>

  if (errorFaq)
    return (
      <div className='row text-center'>
        Lo sentimos. Hay un problema cargando la pagina. Comuníquese con su administrador del sistema
      </div>)

  return (
    <div className='container'>

      <div className='row mt-5'>
        <div >

          <h2 className='text-center'><strong>Preguntas Frecuentes</strong></h2>
          {
            (!loadingFaq) ?
              dataFaq.map(faq => {
                return (
                  <Accordion key={faq.Id}>
                    <Accordion.Item eventKey={faq.Id}>
                      <Accordion.Header>
                        {faq.Question}
                      </Accordion.Header>
                      <Accordion.Body>
                        {faq.Answer}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )
              }) :
              <></>
          }
        </div>
      </div>
    </div>
  )
}

export default PreguntasFrecuentes