import React from 'react'
import { Modal } from 'react-bootstrap'

export const ModalSolicitudVacante = ({ show, setShow }) => {
    const handleClose = () => setShow(false)

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <h3><strong>Solicitud vacante</strong></h3></Modal.Header>
            <Modal.Body className='text-center'>
                <iframe
                    title='Contactenos'
                    // className="embed-responsive-item"
                    width={680}
                    height={810}
                    src="https://share.hsforms.com/1JAxMDD9OT2-NuYNrA9T4hQ4lyfd">
                </iframe>
            </Modal.Body>
        </Modal>
    )
}
