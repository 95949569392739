import React from 'react'
import BannerPagina from '../components/BannerPagina'
import WhatsappButton from '../components/WhatsappButton'
import CarRequirements from '../components/CardRequirements'

//images
import imgOtrosProductos from './img/tarjeta_credito.jpg'
import imgCreditoHipotecario from "./img/credito_hipotecario_2.jpg"
import imgLogoAvvillas from './img/logo_avvillas.jpg'

const OtrosProductos = () => {
  return (
    <>
      <BannerPagina pagina="Otros_Productos" />
      <div className="container">
        <div className="row text-center">
          <h2>Requisitos</h2>
          <hr className='hrblue' />
        </div>
        <div className="row justify-content-md-center">
          <CarRequirements
            title="TARJETA DE CRÉDITO"
            subtitle={`Ser mayor dedad.
            -Buen historial crediticio.
            -Comprobar ingresos.
            -Aplican compras de cartera.
            -Ingresos superiores a dos salarios mínimos.                      
            `}
            buttonText="Solicita tu crédito"
            buttonUrl=""
            imageUrl={imgOtrosProductos}
          />
          <CarRequirements
            title="CRÉDITO HIPOTECARIO"
            subtitle={`Ser mayor de edad. 
            -Buen historial crediticio.
            -Comprobar ingresos.
            -Aplican compras de cartera.
            -Ingresos del grupo familiar            
            `}
            buttonText="Solicita tu crédito"
            buttonUrl=""
            imageUrl={imgCreditoHipotecario}
          />
        </div>

        <div className="row text-center justify-content-md-center mt-5">
          <h1> Bancos Aliados</h1>
          <hr className='hrblue'/>
          <div className="col-md-2">
            <img src={imgLogoAvvillas} className="img-fluid" alt="" />
          </div>
          
        </div>
        <WhatsappButton />
      </div>
    </>
  )
}

export default OtrosProductos