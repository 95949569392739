import React from 'react'
import { useState } from 'react'

const { REACT_APP_CMS_URL } = process.env

const BuscarObligaciones = () => {

  const [clientId, setClientId] = useState('')
  const [showClientDebtsTable, setShowClientDebtsTable] = useState(false)
  const [dataClientDebt, setDataClientDebt] = useState([])
  const [showNotfound, setshowNotfound] = useState(false)

  const searchclientDebts = async () => {
    //const { dataCms: dataClientDebts, errorCms, loadingCms } = useFetch(REACT_APP_CMS_URL + "clientdebts/" + clientId, "CMS", "")
    let res = await fetch(REACT_APP_CMS_URL + "clientdebts/" + clientId, {headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`}})
    const jsonResponse = await res.json();

    if (jsonResponse.length>0)
    {
      setShowClientDebtsTable(true)
      setshowNotfound(false)
    }
    else{
      setshowNotfound(true)
    }
    setDataClientDebt(jsonResponse)
    window.scroll(0, 200)
  }

  const handleClientId = (e) => {
    setClientId(e.target.value)
    setShowClientDebtsTable(false)
  }

  return (
    <div className='row my-5 justify-content-md-center '>

      <div className="col-md-5 text-center">
        <h2>Validación de Cédula</h2>
        <div className="card shadow" >
          <div className="card-body">
            <h5 className="card-title">
              <strong>Verifica si tienes obligaciones con otra entidad</strong>
            </h5>
            <hr className='hrblue' />
            <p className="card-text">Digita el número de tu cédula</p>
            <div className="row mx-2 my-3">
              <div className="col-md-6">
                <input
                  type="text"
                  placeholder='Identificacion'
                  className='form-control text-center '
                  value={clientId}
                  onChange={(e) => handleClientId(e)}
                />
              </div>
              <div className="col-md-6">
                <button className='btn btn-secondary' onClick={() => searchclientDebts()}>Buscar</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showClientDebtsTable ?
        (
          <div className='row justify-content-md-center mt-2'>
            <div className='col-md-8 m-4 bg-light shadow rounded'>
              <hr className='hrblue' />
              <table className='table table-hover '>
                <thead >
                  <tr>
                    <th scope="col">Portafolio</th>
                    <th scope="col">Cédula</th>
                    <th scope="col">Código Obligación</th>
                    <th scope="col">Nombre del deudor</th>
                    {/* <th scope="col">Saldo Capital</th> */}
                    {/* <th scope="col">Saldo Total</th> */}
                  </tr>
                </thead>
                <tbody>

                  {dataClientDebt.map(debt => (
                    <tr key={debt.Id}>
                      <th scope="row">{debt.Portfolio}</th>
                      <td>{debt.IdClient}</td>
                      <td>{debt.LoanId}</td>
                      <td>{debt.Name}</td>
                      {/* <td>{new Intl.NumberFormat().format(debt.PrincipalBalance)}</td> */}
                      {/* <td>{new Intl.NumberFormat().format(debt.TotalBalance)}</td> */}

                    </tr>

                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) :
        <>
        {showNotfound ? (
          <div className='row my-2 justify-content-md-center '>
            <div className='col-md-8 m-4 bg-light shadow rounded'>
            <div className='alert alert-info my-3'>No se han encontrado datos para esta identificación</div>
            </div>
          </div>
        ):
        <></>}
        </>
      }
    </div>
  )
}

export default BuscarObligaciones