import React from "react";
import BannerPagina from "../components/BannerPagina";
import WhatsappButton from "../components/WhatsappButton";
// import imgMap from './img/map_renovar1.png'

const Contactenos = () => {
  return (
    <>
      <BannerPagina pagina="Contactanos" />
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              {/* <img src={imgMap} alt="" /> */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1988.4199118893578!2d-74.06775628956804!3d4.622650508203925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f99866eb1f6b3%3A0x6479ca963ca5b0d8!2sCl.%2035%20%237-25%2C%20Bogot%C3%A1%2C%20Colombia!5e0!3m2!1ses!2sus!4v1685986120224!5m2!1ses!2sus"
                width="600"
                height="450"
                style={{ border: "0px" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="Direccion"
              ></iframe>
            </div>
            <div className="row">
              <hr className="hrblue" />
              <p>
                <strong>Bogotá D.C, Colombia </strong>{" "}
              </p>

              <p>Dirección: Calle 35 # 7 - 25, Piso 3</p>
              <p>Edificio Caxdac.</p>
              <p>Teléfono: (+57)(601) 756 3468</p>
              <hr className="hrblue" />

              <p>
                <strong>Santiago de Cali, Colombia</strong>
              </p>
              <p>Dirección: Carrera 4 # 8 - 39, Oficina 1001</p>
              <p>Edificio Benjamín Herrera. </p>
              {/* <p>Teléfono: (+57)(601) 756 3468</p> */}
              <hr className="hrblue" />
              <p>
                <strong>Correo:</strong> servicioalcliente@renovarfinanciera.com
              </p>
            </div>
          </div>
          <div className="col-md-4 text-center">
            <h2>Contáctanos</h2>
            <div id="pazysalvo" className="row">
              <div className="text-center">
                <div className=" embed-responsive embed-responsive-16by9">
                  <iframe
                    title="Contactenos"
                    className="embed-responsive-item"
                    width={480}
                    height={810}
                    src="https://share.hsforms.com/1w4fVAwefTUW3AFAIbXxiOg4lyfd"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WhatsappButton />
    </>
  );
};

export default Contactenos;
