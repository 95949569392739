import { useEffect } from "react";
import { useState } from "react";

export const useResolution = () => {
    const [resolution, setResolution] = useState(0);

    useEffect(() => {
        const tablets = window.screen.width;
        setResolution(tablets)
    }, [])

    return { resolution }

}