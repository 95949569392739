import { useEffect, useState } from 'react'

const { REACT_APP_CMS_TK } = process.env

const useFetch = (url, fetchType, urlFilter="", data="") => {

    fetchType = fetchType.toUpperCase()
    const [dataCms, setDataCms] = useState(null)
    const [errorCms, setError] = useState(null)
    const [loadingCms, setLoading] = useState(true)


    useEffect(() => {

        const fetchData = async () => {
            setLoading(true)

            try {
                let res = null
                if (fetchType === "CMS-QUITAR_ESTA_PALABRA_DESPUES") {
                    res = await fetch(url + urlFilter, {
                        method: 'get',
                        headers: {
                            'Authorization': 'Bearer ' + REACT_APP_CMS_TK,
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    })
                    const jsonResponse = await res.json();
                    setDataCms(jsonResponse)
                } 
                else if (fetchType==="CMS_POST") {
                    res = await fetch(url, {
                        method: 'post',
                        headers: {                            
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(data),
                    })
                    const jsonResponse = await res.json();
                    //console.log("USEfetch")

                    setDataCms(jsonResponse) 
                }
                else {
                    res = await fetch(url)
                    const jsonResponse = await res.json();
                    setDataCms(jsonResponse)
                }


                setLoading(false)
            } catch (errorFetch) {
                setError(errorFetch)
                setLoading(false)
            }
        }

        fetchData();

    }, [url, data])

    return { dataCms, errorCms, loadingCms }

}

export default useFetch