import React from 'react'
import logopse from './img/pse1.png';

const PseButton = ({typePse = 'FIXED', linkPse ='https://sadminweb.sadmin.net/PayValida.aspx?id9MC/7TNSlVbIa4aSWV4A=='}) => {
  let stylePse = {}
  if(typePse === "FIXED")
  {
      stylePse = {
      position: 'fixed', top: '75%', right: 0
    }
  }

  return (
    <>
    <a className="navbar-brand" style={stylePse} href={linkPse}>
          <img src={logopse} width="60" height="60" className="d-inline-block align-top py-0" alt="" />
        </a>
    </>
  )
}

export default PseButton