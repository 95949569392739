import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Flip } from 'react-reveal'
import { ModalSolicitudVacante } from './ModalSolicitudVacante';
import "./css/CardVacantes.css";

const CardVacantes = ({ title, description, date, amount, requirements }) => {
    const [show, setShow] = useState(false);
    return (
        <>
            <Flip top>
                <div className="card shadow card_width" >
                    <div className="card-body">
                        <h5 className="card-title"><strong>{title}</strong></h5>
                        <hr className="hrblue" />
                        <h6 className="card-subtitle fw-bold p-2">Descripción</h6>
                        <p>{description}</p>
                        <h6 className='card-subtitle fw-bold p-2'>Requisitos</h6>
                        <p>{requirements}</p>
                        <p><strong>Fecha publicación:</strong> {date}</p>
                        <p><strong>Salario desde:</strong> {new Intl.NumberFormat().format(amount)} COP</p>
                    </div>
                    <div className="card-footer">
                        <Button variant="secondary" onClick={() => { setShow(true) }}>
                            Solicitar
                        </Button>
                    </div>
                </div>
            </Flip>
            {show && <ModalSolicitudVacante show={show} setShow={setShow} />}
        </>
    )
}

export default CardVacantes