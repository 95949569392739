import React, { useEffect, useState } from 'react'
import CardVacantes from './CardVacantes';

export const Vacantes = () => {
    const [vacantes, setVacantes] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_CMS_URL}vacants`, {headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`}})
            .then(async (res) => {
                const data = await res.json()
                setVacantes(data)
                setLoading(false);
            }).catch((err) => {
                setVacantes(null)
                setLoading(false);
            })
    }, [])

    if (loading) return <>Cargando información...</>
    else if (vacantes == null) return <>Ha surgido un problema al cargar las vacantes</>
    else {
        return (
            <div className='d-flex gap-5 flex-wrap justify-content-center'>
                {vacantes.length === 0 ?
                    <h2 className='text-center'>No hay vacantes disponibles en este momento.</h2>
                    : vacantes.map((vacante) => (
                        <CardVacantes title={
                            vacante.Title
                        }
                            description={vacante.Description}
                            date={vacante.Date}
                            amount={vacante.Amount}
                            requirements={vacante.Requirements}
                            key={`vacante-${vacante.Title}`} />
                    ))}
            </div>
        )
    }
}
