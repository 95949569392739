import { BrowserRouter, Routes, Route } from "react-router-dom";
//import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";


// Pages and Layouts imports
import BarraMenu from "./components/BarraMenu";
import PiePagina from "./components/PiePagina";
import Consumo from './pages/Consumo'
import Contactenos from './pages/Contactenos'
import Inicio from './pages/Inicio'
import Libranza from './pages/Libranza'
import MediosPago from './pages/MediosPago'
import Microcredito from './pages/Microcredito'
import PreguntasFrecuentes from './pages/PreguntasFrecuentes'
import QuienesSomos from './pages/QuienesSomos'
import SobreTuDeuda from './pages/SobreTuDeuda'
import SolucionesCobranza from './pages/SolucionesCobranza'
import TrabajeConNosotros from './pages/TrabajeConNosotros'
import OtrosProductos from "./pages/OtrosProductos";

// const { REACT_APP_CMS_IMAGES_URL, REACT_APP_CMS_TK } = process.env


// // apollo client
// const clientApolloManager = new ApolloClient({
//   uri: REACT_APP_CMS_IMAGES_URL + '/graphql',
//   headers: {
//     authorization: `Bearer ${REACT_APP_CMS_TK}`
//   },
//   cache: new InMemoryCache()
// });

function App() {
  return (
    <>
      <BarraMenu />
      <BrowserRouter>
        {/* <ApolloProvider client={clientApolloManager}> */}
          <Routes>
            <Route path="/" element={<Inicio />} />
            <Route path="/quienessomos" element={<QuienesSomos />} />
            <Route path="/mediospago" element={<MediosPago />} />
            <Route path="/sobretudeuda" element={<SobreTuDeuda />} />
            <Route path="/microcredito" element={<Microcredito />} />
            <Route path="/libranza" element={<Libranza />} />
            <Route path="/consumo" element={<Consumo />} />
            <Route path="/otrosproductos" element={<OtrosProductos />} />
            <Route path="/solucionescobranza" element={<SolucionesCobranza />} />
            <Route path="/contactenos" element={<Contactenos />} />
            <Route path="/trabajeconnosotros" element={<TrabajeConNosotros />} />
            <Route path="/preguntasfrecuentes" element={<PreguntasFrecuentes />} />
          </Routes>
        {/* </ApolloProvider> */}
      </BrowserRouter>
      <PiePagina />
    </>
  );
}

export default App;
