import React from 'react'
import chatbot_logo from './img/chatbot_logo.png';

export const ChatbotButton = ({ type = 'FIXED', link = 'https://irisrenovarfinanciera.adminfo.net/' }) => {
    let stylePse = {}
    if (type === "FIXED") {
        stylePse = {
            position: 'fixed', top: '65%', right: 0
        }
    }
    return (
        <a className='navbar-brand' style={stylePse} href={link} target='_blank' rel='noreferrer'>
            <img src={chatbot_logo} width="60" height="60" className='d-inline-block align-top py-0' alt='chatbot'/>
        </a>
    )
}
