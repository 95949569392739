import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

// components
import PreguntasFrecuentes from './PreguntasFrecuentes';
import BuscarObligaciones from '../components/BuscarObligaciones';
import WhatsappButton from '../components/WhatsappButton';
import PseButton from '../components/PseButton';
import BannerPagina from '../components/BannerPagina';
import ProcessStep from '../components/ProcessStep';

//images
import imgBannerProcess from './img/banner_process2.jpg'
import imgVinculacion from '../components/img/vinculacion.png'
import imgAnalisis from '../components/img/analisis.png'
import imgAcuerdos from '../components/img/acuerdos.png'
import imgReincorporacion from '../components/img/reincorporacion.png'

const SobreTuDeuda = () => {
  const [show, setShow] = useState(false);
  const [modalTitle, setmodalTitle] = useState("Solicita tu Paz y Salvo")
  const handleClose = () => setShow(false);
  const handleShow = (title) => {
    setShow(true);
    setmodalTitle(title);
  }

  return (
    <>
      <BannerPagina pagina="SOBRE_TU_DEUDA" />
      <div className="row px-5 justify-content-md-center">
        <BuscarObligaciones />
        <div className="col-md-5 text-center gx-5">
          <h2>Información del crédito</h2>
          <div className="card shadow" >
            <div className="card-body">
              <h5 className="card-title">Solicitar tu saldo</h5>
              <hr className='hrblue' />
              <p className="card-text">Diligencia el formato para poder enviarte toda la información sobre el estado de tu
                cuenta.</p>
              <Button variant="secondary" onClick={() => handleShow("Solicita tu saldo")}>
                Solicitar
              </Button>
            </div>
          </div>
        </div>
        <div className="col-md-5 text-center gx-5">
          <h2>Paz y Salvo</h2>
          <div className="card shadow" >
            <div className="card-body">
              <h5 className="card-title">Solicita tu paz y salvo</h5>
              <hr className='hrblue' />
              <p className="card-text">Ingresa aquí para generar tu paz y salvo como titular o codeudor.</p>
              <Button variant="secondary"
                // onClick={() => handleShow("Solicita tu Paz y Salvo")}
                onClick={() => { window.open("https://irisrenovarfinanciera.adminfo.net/") }}
              >
                Solicitar
              </Button>
            </div>
          </div>
        </div>

        {/* <div className="col-md-3 text-center gx-5">
          <h2>Codeudor</h2>
          <div className="card shadow" >
            <div className="card-body">
              <h5 className="card-title">Solicitar paz y Salvo</h5>
              <hr className='hrblue' />
              <p className="card-text">Ingresa aquí para generar tu paz y salvo de Codeudor.</p>
              <Button variant="secondary" onClick={() => handleShow("Solicita tu Paz y Salvo")}>
                Solicitar
              </Button>
            </div>
          </div>
        </div> */}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton >
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            title='Paz y Salvo'
            className="embed-responsive-item"
            width={480}
            height={810}
            src="https://share.hsforms.com/18aON9Vn4SEaIH4dMVWvJzg4lyfd"
            allow='allow-scripts allow-modal'
          >
          </iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="row mb-5"></div>
      <div className="row my-4">
        <img src={imgBannerProcess} alt="Proceso Renovar" />
      </div>
      <div className="container">
        <div className="row text-center my-4">
          <h2><strong>Nuestro proceso</strong></h2>
          <hr className='hrblue' />
        </div>
        <div className="row">
          <ProcessStep
            imageUrl={imgVinculacion}
            title="1. Vinculación de los titulares"
          />
          <ProcessStep
            imageUrl={imgAnalisis}
            title="2. Análisis de situación particular de cada titular"
          />
          <ProcessStep
            imageUrl={imgAcuerdos}
            title="3. Gestión de acuerdos con titulares"
          />
          <ProcessStep
            imageUrl={imgReincorporacion}
            title="4. Reincorporación a la vida crediticia"
          />
        </div>
      </div>
      <PreguntasFrecuentes />
      <WhatsappButton />
      <PseButton />

    </>
  )
}

export default SobreTuDeuda