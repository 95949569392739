import React from 'react'
import BannerPagina from '../components/BannerPagina'
import Fade from 'react-reveal/Fade';

//images
import imgAdminCobranzas from './img/admin_cobranzas.png'
import imgCompraPortafolios from './img/compra_portafolios.png'
import WhatsappButton from '../components/WhatsappButton';
import imgServicioCobrar from './img/administracion_cobranzas1.jpg'
import CardAnimatedCobranza from '../components/CardAnimateCobranza';

const SolucionesCobranza = () => {
  return (
    <>
      <BannerPagina pagina="Soluciones_Cobranzas" />
      <div className="container">
        <div className="row text-center">
          <h1> ¿Por qué somos tu mejor opción?</h1>
        </div>
        <hr className='hrblue' />
        <div className="row justify-content-md-center mt-5">
          {/* <div className='col-md-4 text-center gx-4 my-0'>
            <h2> Compra de Portafolios</h2>
          </div> */}
          {/* <div className='col-md-4 text-center gx-4 my-0'>
            <h2> Administración de cobranzas</h2>
          </div> */}
        </div>
        <div className='row justify-content-md-center '>

          <Fade top>
            <div className='col-md-4 text-center gx-4'>
              <div className="card shadow " >
                <img src={imgCompraPortafolios}
                  height="210px" width="210px"
                  className="mx-auto pt-3"
                  alt="Solucion para tu deuda" />
                <div className="card-body">
                  <h5 className="card-title"><strong> ADN RENOVAR FINANCIERA</strong></h5>
                  <hr className='hrblue' />
                  <p className="card-text" >
                    Nuestros titulares son seres humanos llenos de potencial y capacidades
                    productivas, que merecen una segunda oportunidad para reconstruir su
                    vida crediticia y aportar al aparato productivo nacional.
                  </p>
                  <br></br>
                  <a href="/contactenos" className="btn btn-secondary">Contáctanos</a>
                </div>
              </div>
            </div>
          </Fade>

          <Fade top>
            <div className="col-md-4 text-center gx-4">
              <div className="card shadow" >
                <img src={imgAdminCobranzas}
                  height="210px" width="210px"
                  className="mx-auto pt-3"
                  alt="Cobranza" />
                <div className="card-body">
                  <h5 className="card-title"><strong>CAPITAL HUMANO</strong></h5>
                  <hr className='hrblue' />
                  <p className="card-text">
                    Contamos con un equipo humano altamente capacitado en el manejo
                    relacional durante la gestión de cobro, buscando siempre mantener
                    al más alto nivel los estándares reputacionales de los originadores
                    de las carteras.
                  </p>
                  <br></br>

                  <a href="/contactenos" className="btn btn-secondary">Contáctanos</a>
                </div>
              </div>
            </div>
          </Fade>
        </div>
        <div className="row text-center mt-5">
          <h1> Servicios</h1>
        </div>
        <hr className='hrblue' />
        <div className='row justify-content-md-center '>
          {/* <CardAnimated
            title="Compra de portafolios de cartera"
            subtitle="Restaurar la vida crediticia"
            buttonText="Contáctanos"
            buttonUrl="/contactanos"
            imageUrl={imgCompraCartera}
          /> */}
          <CardAnimatedCobranza
            title="Administración y recuperación de cartera"
            subtitle="Gestión de cobro especializada"
            buttonText="Contáctanos"
            buttonUrl="/contactanos"
            imageUrl={imgServicioCobrar}
          />
        </div>
        <WhatsappButton />

      </div>
    </>

  )
}

export default SolucionesCobranza