import React from 'react';
import Flip from 'react-reveal/Flip';

const CardAnimated = ({ title, subtitle, buttonText, buttonUrl, imageUrl, columns = 4, fixedHeight = 80 }) => {
    let classColumns = "col-md-3 gx-5 text-center";
    if (columns === 3)
        classColumns = "col-md-4 gx-5 text-center";

    if (columns === 2)
        classColumns = "col-md-5 gx-4 text-center";

    if (columns === 5)
        classColumns = "col-md-2 text-center";

    let styleHeight = { height: fixedHeight };
    if (fixedHeight === 0)
        styleHeight = {};

    return (
        <>
            <Flip top>
                <div className={classColumns}>
                    <div className="card shadow my-3"  >
                        <img src={imageUrl} className="card-img-top" alt={title} />
                        <div className="card-body">
                            <h5 className="card-title"><strong>{title}</strong></h5>
                            <hr className='hrblue' />
                            <div className="card-text" style={styleHeight}>{subtitle}</div>
                            {
                                buttonText.length > 0 ?
                                    <a href={buttonUrl} className="btn btn-outline-secondary mb-3">{buttonText}</a>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
            </Flip>
        </>
    )
    // return (
    //     <>
    //         <Flip top>
    //             <div className="d-flex shadow border rounded justify-content-between p-5 mt-3">
    //                 <div className="col-md-4 text-center gx-4">
    //                     <div className="card-body">
    //                         {/* <h5 className='card-title'><strong>{title}</strong></h5> */}
    //                         <h2 className='card-title'><strong>{title}</strong></h2>
    //                         <hr className='hrblue' />
    //                         <p className="card-text"> {subtitle}</p>
    //                         <br></br>
    //                         <a href="/sobretudeuda" className="btn btn-outline-secondary">{buttonText}</a>
    //                     </div>
    //                 </div>
    //                 <div className="col-md-4 text-center gx-4">
    //                     <img src={imageUrl}
    //                         className='mx-auto pt-3'
    //                         alt='Solucion para tu deuda' />
    //                 </div>
    //             </div>
    //         </Flip>
    //     </>
    // )
}

export default CardAnimated