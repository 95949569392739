import React from 'react'
import BannerPagina from '../components/BannerPagina'
import { Vacantes } from '../components/Vacantes'

const TrabajeConNosotros = () => {
  return (
    <>
      <BannerPagina pagina="Trabaje_Con_Nosotros" />
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-12 text-center">
            <h2>Trabaja con Nosotros</h2>
            <hr className='hrblue' />
            <div id='pazysalvo' className='row'>
              <div className="col">
                <Vacantes />
              </div>
              {/* <div className="text-center">
                <div className=" embed-responsive embed-responsive-16by9">
                  <iframe
                    title='Contactenos'
                    className="embed-responsive-item"
                    width={680}
                    height={810}
                    src="https://share.hsforms.com/1JAxMDD9OT2-NuYNrA9T4hQ4lyfd">
                  </iframe>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TrabajeConNosotros