import React from 'react'
import { Fade } from 'react-reveal'
import { useResolution } from '../hooks/useResolution';


export const CardsInicio = ({ imgEresDeudor, imgRecuperaCartera }) => {

    const { resolution } = useResolution();

    if (resolution > 425) {
        return (
            <>
                <div className="row mt-5">
                    <Fade top>
                        <div className="row" style={{ height: "25rem" }}>
                            <div
                                className="col-md-6"
                                style={{
                                    backgroundImage: `url(${imgEresDeudor})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center"
                                }}
                            ></div>
                            <div
                                className="col-md-6 text-center"
                                style={{ backgroundColor: "#FCFCFC" }}
                            >
                                <h3 className="card-title py-2">Para personas</h3>
                                <h1 className="card-title my-4">¿Eres deudor(a)?</h1>
                                <p className="card-text my-4">
                                    ¡Tenemos beneficios para ti! Paga tus deudas con la asesoría
                                    de nuestro equipo de cobranza
                                </p>
                                <br />
                                <a href="/sobretudeuda" className="btn btn-outline-secondary">
                                    Conoce los beneficios
                                </a>
                            </div>
                        </div>
                    </Fade>
                </div>
                <div className="row mt-5">
                    <Fade top>
                        <div className="row" style={{ height: "25rem" }}>
                            <div
                                className="col-md-6 text-center"
                                style={{ backgroundColor: "#FCFCFC" }}
                            >
                                <h3 className="card-title py-2">Soluciones para empresas</h3>
                                <h1 className="card-title my-4">Recupera tu cartera</h1>
                                <p className="card-text my-4">
                                    Administramos el cobro de cartera para tu empresa con un
                                    equipo experimentado que se adapta a las necesidades
                                </p>
                                <br />
                                <a
                                    href="/solucionescobranza"
                                    className="btn btn-outline-secondary"
                                >
                                    Conoce los beneficios
                                </a>
                            </div>
                            <div
                                className="col-md-6"
                                style={{
                                    backgroundImage: `url(${imgRecuperaCartera})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center"
                                }}
                            ></div>
                        </div>
                    </Fade>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="row mt-5 justify-content-center">
                    <Fade top>
                        <div className="row" style={{ height: "auto" }}>
                            <div
                                className="col-md-6"
                                style={{
                                    backgroundImage: `url(${imgEresDeudor})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    height: "20rem"
                                }}
                            ></div>
                            <div
                                className="col-md-6 text-center"
                                style={{ backgroundColor: "#FCFCFC" }}
                            >
                                <h3 className="card-title py-2">Para personas</h3>
                                <h1 className="card-title my-4">¿Eres deudor(a)?</h1>
                                <p className="card-text my-4">
                                    ¡Tenemos beneficios para ti! Paga tus deudas con la asesoría
                                    de nuestro equipo de cobranza
                                </p>
                                <br />
                                <a href="/sobretudeuda" className="btn btn-outline-secondary">
                                    Conoce los beneficios
                                </a>
                            </div>
                        </div>
                    </Fade>
                </div>

                <div className="row mt-5 justify-content-center">
                    <Fade top>
                        <div className="row" style={{ height: "auto" }}>
                            <div
                                className="col-md-6"
                                style={{
                                    backgroundImage: `url(${imgRecuperaCartera})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    height: "20rem"
                                }}
                            ></div>
                            <div
                                className="col-md-6 text-center"
                                style={{ backgroundColor: "#FCFCFC" }}
                            >
                                <h3 className="card-title py-2">Soluciones para empresas</h3>
                                <h1 className="card-title my-4">Recupera tu cartera</h1>
                                <p className="card-text my-4">
                                    Administramos el cobro de cartera para tu empresa con un
                                    equipo experimentado que se adapta a las necesidades
                                </p>
                                <br />
                                <a
                                    href="/solucionescobranza"
                                    className="btn btn-outline-secondary"
                                >
                                    Conoce los beneficios
                                </a>
                            </div>

                        </div>
                    </Fade>
                </div>
            </>
        )
    }

}
