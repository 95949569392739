import React, { useState } from 'react'
import imgSimulador from './img/simulador2.jpg'
import useFetch from '../hooks/useFetch'
import { useEffect } from 'react'

const { REACT_APP_CMS_URL } = process.env

const SimuladorMicrocredito = ({ creditVariables }) => {

    const [amount, setAmount] = useState(creditVariables.MinimalAmount)
    const [placeholder, setPlaceholder] = useState(creditVariables.MinimalAmount)
    const [installments, setInstallments] = useState(1)
    const [warranty, setWarranty] = useState(creditVariables.MinimalAmount * creditVariables.WarrantyRate / 100)
    const [mipyme, setMipyme] = useState(creditVariables.MinimalAmount * creditVariables.MipymeRate / 100)
    const [iva, setIva] = useState(
        Math.round((mipyme * creditVariables.MipymeTax / 100) +
            (creditVariables.ExpensesValue * creditVariables.ExpensesTax / 100), 0)
    )
    const [totalAmount, setTotalAmount] = useState(amount + warranty + mipyme + iva + creditVariables.ExpensesValue)
    const [creditData, setCreditData] = useState({
        "amount": totalAmount,
        "interest_rate": creditVariables.InterestRate + creditVariables.InsuranceRate,
        "installments": 1
    })

    const [dataInstallment, setDataInstallment] = useState(null);

    const getInstallment = () => {
        fetch(`${process.env.REACT_APP_CMS_URL}installment`, {
            body: JSON.stringify(creditData),
            method: "POST",
            headers: { "Authorization": `Bearer ${localStorage.getItem("token")}`, 'Content-Type': 'application/json' }
        }
        ).then(async (res) => {
            const data = await res.json()
            setDataInstallment(data)
        }).catch((err) => {
            setDataInstallment(null)
        })
    }

    useEffect(() => {
        getInstallment();
    }, [creditData])

    const handlePlaceholder = (value) => {
        setPlaceholder(value)
    }

    const handleAmount = (value) => {
        let valorEntero = parseInt(value)
        if (isNaN(valorEntero)) {
            valorEntero = creditVariables.MinimalAmount
            setPlaceholder(creditVariables.MinimalAmount)
        }
        else if (valorEntero > creditVariables.MaximumAmount) {
            valorEntero = creditVariables.MaximumAmount
            setPlaceholder(creditVariables.MaximumAmount)
        }
        else if (valorEntero < creditVariables.MinimalAmount) {
            valorEntero = creditVariables.MinimalAmount
            setPlaceholder(creditVariables.MinimalAmount)

        }
        if (valorEntero % 10000 !== 0) {
            const valorFaltante = valorEntero % 10000;
            valorEntero = valorEntero + (10000 - valorFaltante)
        }
        let warrantyChanged = Math.round(valorEntero * creditVariables.WarrantyRate / 100, 0)
        let mipymeChanged = Math.round(valorEntero * creditVariables.MipymeRate / 100, 0)
        let taxChanged = Math.round((mipymeChanged * creditVariables.MipymeTax / 100) +
            (creditVariables.ExpensesValue * creditVariables.ExpensesTax / 100), 0)
        let totalAmountChanged = valorEntero + warrantyChanged + mipymeChanged + taxChanged + creditVariables.ExpensesValue
        setPlaceholder(valorEntero)
        setAmount(valorEntero)
        setCreditData({
            ...creditData,
            amount: totalAmountChanged
        })
        setWarranty(warrantyChanged)
        setMipyme(mipymeChanged)
        setIva(taxChanged)
        setTotalAmount(totalAmountChanged)
    }


    const handleInstallments = (e) => {
        // setInstallments(e.target.value)
        setCreditData({
            ...creditData,
            installments: parseInt(e.target.value)
        })

    }

    return (
        <div className='bg-light shadow rounded text-center my-5'>
            <div className="row pt-1">
                <img src={imgSimulador} alt='' />
            </div>
            <div className="container">

                <div className="row p-4">
                    <div className="row-md-6 col-sm-12">
                        <label className='form-label'>¿Cuánto dinero necesitas?</label>
                        <input
                            className="form-control form-control-lg mb-3"
                            type="text" aria-label="Cuanto necesitas"
                            // readOnly
                            onChange={(e) => { handlePlaceholder(e.target.value.replace("$", "").trim().replaceAll(",", "").replaceAll(".", "")) }}
                            onBlur={(e) => handleAmount(e.target.value.replace("$", "").trim().replaceAll(",", "").replaceAll(".", ""))}
                            value={"$ " + new Intl.NumberFormat().format(placeholder)}
                        />
                        <input
                            type="range"
                            style={{ width: "100%" }}
                            // className="form-range"
                            min={creditVariables.MinimalAmount}
                            max={creditVariables.MaximumAmount}
                            step="10000"
                            id="loanAmountRange"
                            onChange={(e) => { handlePlaceholder(e.target.value.replace("$", "").trim().replaceAll(",", "").replaceAll(".", "")) }}
                            onMouseUp={(e) => handleAmount(e.target.value)}
                        // onChange={(e) => handleAmount(e.target.value)} 
                        // onBlur={(e) => handleAmount(e.target.value)}
                        />
                    </div>
                    <div className="row-md-6 col-sm-12">
                        <label className='form-label'>Selecciona el plazo:</label>
                        <input
                            className="form-control form-control-lg mb-3"
                            type="number"
                            aria-label="Cuotas"
                            readOnly
                            value={installments} />
                        <input
                            type="range"
                            // className="form-range"
                            style={{ width: "100%" }}
                            min={1}
                            max={creditVariables.NumberOfInstallments}
                            step="1"
                            id="loanInstallmentRange"
                            onChange={(e) => setInstallments(e.target.value)}
                            onMouseUp={(e) => handleInstallments(e)}
                            value={installments} />
                    </div>
                </div>
            </div>

            <div className="row">
                <strong>Valor de la cuota</strong>
                <label>
                    {dataInstallment != null ? "$ " +
                        new Intl.NumberFormat().format(dataInstallment.data.installment_value) : 0}
                </label>
            </div>
            <hr className='hrblue' />
            <div className="row justify-content-center">
                <strong>Resumen del crédito</strong>
                <div className="row sm:justify-content-center">
                    <div className="col-md-6">
                        Garantía
                    </div>
                    <div className="col-md-6 md:text-start">
                        {"$ " + new Intl.NumberFormat().format(warranty)}
                    </div>
                </div>
                <div className="row sm:justify-content-center">
                    <div className="col-md-6">
                        Ley MiPyme
                    </div>
                    <div className="col-md-6 md:text-start">
                        {"$ " + new Intl.NumberFormat().format(mipyme)}
                    </div>
                </div>
                <div className="row sm:justify-content-center">
                    <div className="col-md-6">
                        Gastos de Originación:
                    </div>
                    <div className="col-md-6 md:text-start">
                        {"$ " + new Intl.NumberFormat().format(creditVariables.ExpensesValue)}
                    </div>
                </div>
                <div className="row sm:justify-content-center">
                    <div className="col-md-6">
                        IVA:
                    </div>
                    <div className="col-md-6 md:text-start">
                        {"$ " + new Intl.NumberFormat().format(iva)}
                    </div>
                </div>
                <div className="row sm:justify-content-center">
                    <div className="col-md-6">
                        <strong>Total del crédito:</strong>
                    </div>
                    <div className="col-md-6 md:text-start">
                        {"$ " + new Intl.NumberFormat().format(totalAmount)}
                    </div>
                </div>
            </div>

            <div className='row justify-content-md-center text-center'>
                <div className="col-md-4 my-3">
                    <a href="https://sadminfactory.sadmin.net/autologin?YzJGa2JXbHVjbVZ1YjNaaGNsODVNREUwTURBMk1EbGZabUZqZEc5eWVRPT18MXwwfDB8MDE=" target="_blank" rel='noreferrer' className="btn btn-secondary">Solicita tu crédito</a>
                </div>
            </div>
            <div className="row fs-6 px-4 pb-2">
                <p>
                </p><small> Los valores son aproximados y pueden variar según las condiciones de desembolso</small>
            </div>
        </div>
    )
}

export default SimuladorMicrocredito