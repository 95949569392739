import React from 'react'

const ProcessStep = ({ imageUrl, title, columns = 4 }) => {
    let classColumn = "col-md-3 gx-5";
    if (columns === 2)
        classColumn = "col-md-6 gx-5";

    if (columns === 3)
        classColumn = "col-md-4 gx-5";

    if (columns === 6)
        classColumn = "col-md-2 gx-5";

    if (columns === 0)
        classColumn = ""

    return (
        <div className={classColumn}>
            <div className="row justify-content-md-center">
                <div className="col-md-8 text-center">
                    <img src={imageUrl} className="img-fluid" alt={title} />
                </div>
            </div>
            <hr className='hrblue' />
            <div className="row text-center">
                <h4>{title}</h4>
            </div>
        </div>
    )
}

export default ProcessStep