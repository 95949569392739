import React, { useState, useEffect } from 'react'
import Fade from 'react-reveal/Fade';


const Testimonio = () => {

    const [loadingTestimonio, setLoadingTestimonio] = useState(false);
    const [errorTestimonio, setErrorTestimonio] = useState(false);
    const [dataTestimonio, setDataTestimonio] = useState([]);

    useEffect(() => {
        setLoadingTestimonio(true);
        fetch(`${process.env.REACT_APP_CMS_URL}testimony`, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } })
            .then(async (res) => {
                const data = await res.json()
                setDataTestimonio(data)
                setLoadingTestimonio(false);
            }).catch((err) => {
                setErrorTestimonio(true);
                setDataTestimonio(null)
                setLoadingTestimonio(false);
            })
    }, [])


    if (loadingTestimonio)
        return <div className='row text-center'><h3>Loading ....</h3></div>

    if (errorTestimonio)
        return (
            <div className='row text-center'>
                Lo sentimos. Hay un problema cargando la pagina. Comuniquese con su administrador del sistema
            </div>)

    return (
        <>
            <div className="row mb-5 text-center">
                <h2>¿Qué dicen nuestros clientes?</h2>
                <hr className='hrblue' />
            </div>
            <div className="row">
                {loadingTestimonio === false ?
                    (
                        dataTestimonio.map(testimony => (
                            <div key={testimony.TestimonyId} className="col-md-4 my-2 gx-3">
                                <Fade top>
                                    <div className="row justify-content-md-center">
                                        <div className="col-md-4">
                                            <img
                                                src={testimony.Photo}
                                                className='img-fluid rounded-circle'
                                                alt=''
                                            />

                                        </div>
                                    </div>
                                    <div className="row text-center">
                                        <strong>{testimony.Name}</strong>
                                    </div>
                                    <div className="row text-center">
                                        <small><strong>{testimony.City}</strong></small>
                                    </div>
                                    <div className="row p-4 lead text-center">
                                        <small><em>{testimony.Text}</em></small>
                                    </div>

                                </Fade>

                            </div>
                        )
                        )
                    ) :
                    (
                        <div>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default Testimonio