import React from 'react'
import BannerPagina from '../components/BannerPagina'
import ProcessStep from '../components/ProcessStep'
import SimuladorMicrocredito from './SimuladorMicrocredito'

// images
//import imgMonto from './img/monto1.jpg'
//import imgPlazo from './img/plazo1.png'
import imgCapitalTrabajo from './img/capital_trabajo.png'
import imgActivosFijos from './img/activos_fijos.png'
import imgMejorasLocales from './img/mejoras_locales.png'
import imgMateriaPrima from './img/materia_prima.png'
import imgSaldarDeudas from './img/saldar_deudas.png'
import { useState } from 'react'
import { useEffect } from 'react'

const { REACT_APP_CMS_URL } = process.env

const Microcredito = () => {

  const [dataVar, setDataVar] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const getVariables = async () => {
    try {
      const response = await fetch(`${REACT_APP_CMS_URL}creditvariables/microcredito/`, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } })
      const data = await response.json();
      setDataVar(data);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  }

  useEffect(() => {
    getVariables();
  }, [])


  if (loading)
    return <div className='row text-center'><h3>Loading ....</h3></div>

  if (error)
    return (
      <div className='row text-center'>
        Lo sentimos. Hay un problema cargando la pagina. Comuníquese con su administrador del sistema
      </div>)
  else if (dataVar !== null && dataVar.length !== 0) {
    const creditVariables = dataVar.find(x => x.CreditType === "MICROCREDITO");

    return (
      <>
        <BannerPagina pagina="MICROCREDITO" marginbottom={true} />
        <div className="container">
          <div className="row divsimulator justify-content-md-center">
            <div className="col-md-4 p-5 gx-5 text-light ">
              <div className="row justify-content-md-center ">
                <div className="col-md-6 gx-5 text-center">
                  <h4> <strong>Monto</strong></h4>
                  <hr className='hrwhite' />
                  <h5>$ {new Intl.NumberFormat().format(creditVariables.MinimalAmount)} -
                    ${new Intl.NumberFormat().format(creditVariables.MaximumAmount)}</h5>
                </div>

                <div className="col-md-6 gx-5 text-center">
                  <h4> <strong>Plazo</strong></h4>
                  <hr className='hrwhite' />
                  <h5>Hasta {creditVariables.NumberOfInstallments} meses</h5>
                </div>
              </div>

              <div className="row mt-5">
                <h5 className="text-center"><strong>Requisitos</strong></h5>
                <hr className='hrwhite' />
                <ul className='px-2' >
                  <li>Microempresarios informales con actividad productiva.</li>
                  <li>Microempresarios que hayan cancelado sus obligaciones crediticias castigadas con nuevo estatus crediticio.</li>
                  <li>Microempresarios con inicio de actividad productiva que hayan registrado algún periodo de no continuidad de su negocio.</li>
                  <li>Rango de edad entre los 18 años hasta máximo 70 años.</li>
                  <li>Fuente de ingresos recurrente.</li>
                </ul>
              </div>
            </div>

            <div className="col-md-5 gx-5 ">
              <SimuladorMicrocredito creditVariables={creditVariables} />
            </div>
          </div>

          <div className="row text-center my-5">
            <h2><strong>Beneficios</strong></h2>
            <hr className='hrblue' />
          </div>
          <div className="row justify-content-md-center">
            <ProcessStep
              imageUrl={imgCapitalTrabajo}
              title="Capital de Trabajo"
            />
            <ProcessStep
              imageUrl={imgActivosFijos}
              title="Activos fijos"
            />
            <ProcessStep
              imageUrl={imgMejorasLocales}
              title="Mejoras Locales"
            />
          </div>
          <div className="row justify-content-md-center">
            <ProcessStep
              imageUrl={imgMateriaPrima}
              title="Materia Prima"
            />
            <ProcessStep
              imageUrl={imgSaldarDeudas}
              title="Saldar Deudas"
            />

          </div>
          <div className="row">

          </div>
        </div>
      </>
    )
  }

}

export default Microcredito