import React from 'react'
import BannerPagina from '../components/BannerPagina'
import CarRequirements from '../components/CardRequirements'
import WhatsappButton from '../components/WhatsappButton'

//imagenes
import imgEmpleado from './img/empleado.jpg'
import imgIndependiente from "./img/independiente.jpg"
import imgLogoAvvillas from './img/logo_avvillas.jpg'
import imgLogoBancoBogota from './img/logo_banco_bogota_2.png'
import imgLogoBancoW from './img/logo_bancow.jpg'
import imgLogoBancoUnion from './img/logo_bancounion.png'

const Consumo = () => {
  return (
    <>
      <BannerPagina pagina="Consumo" />
      <div className="container">
        <div className="row text-center">
          <h2>Requisitos</h2>
          <hr className='hrblue' />
        </div>
        <div className="row justify-content-md-center">
          <CarRequirements
            title="EMPLEADOS"
            subtitle={`Tener un contrato laboral mínimo 1 año o continuidad laboral.
            -No reportes activos o contar con paz y salvo.            
            -Aplica Compras de cartera y libre inversión.            
            -Ingresos superiores a 2 salarios.            
            `}
            buttonText="Solicita tu crédito"
            buttonUrl=""
            imageUrl={imgEmpleado}
          />

          <CarRequirements
            title="INDEPENDIENTES"
            subtitle={`Tener cámara de comercio. 
            -Rut 
            -Extractos bancarios 
            -Declaración de renta.
            -No se atienden fuerzas militares.                        
            `}
            buttonText="Solicita tu crédito"
            buttonUrl=""
            imageUrl={imgIndependiente}
          />
        </div>

        <div className="row text-center justify-content-md-center mt-5">
          <h1> Bancos Aliados</h1>
          <hr className='hrblue'/>
          <div className="col-md-2">
            <img src={imgLogoAvvillas} className="img-fluid" alt="" />
          </div>
          <div className="col-md-2">
            <img src={imgLogoBancoBogota} className="img-fluid" alt="" />
          </div>
          <div className="col-md-2">
            <img src={imgLogoBancoW} className="img-fluid" alt="" />
          </div>
          <div className="col-md-2">
            <img src={imgLogoBancoUnion} className="img-fluid" alt="" />
          </div>
        </div>
        <WhatsappButton />
      </div>

    </>
  )
}

export default Consumo