import React from 'react'
import logowhatsapp from './img/whatsapp1.png'

const WhatsappButton = () => {
  return (
    <>
      <a className="navbar-brand" style={{ position: 'fixed', top: '85%', right: 0 }}
        href={`https://api.whatsapp.com/send?phone=573138946373`}>
        <img src={logowhatsapp} width="60" height="60" className="d-inline-block align-top py-0" alt="" />
      </a>
    </>
  )
}

export default WhatsappButton