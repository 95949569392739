import React from 'react'
import BannerPagina from '../components/BannerPagina'
import ProcessStep from '../components/ProcessStep'

//images
import imgMision from './img/mision.jpg'
import imgVision from './img/vision.jpg'
import imgFelicidad from './img/valores_felicidad.png'
import imgInnovacion from './img/valores_innovacion.png'
import imgTransparencia from './img/valores_transparencia.png'
import imgCompromiso from './img/valores_compromiso.png'
import imgTrabajoEquipo from './img/valores_trabajoequipo.png'
import imgIgualdad from './img/valores_igualdad.png'
import imgFacebook from './img/facebook1.png'
import imgInstagran from './img/instagram.png'
import imgTrabajeConNosotros from './img/trabajeconnosotros._smalljpg.jpg'
import CardAnimated from '../components/CardAnimated'


const QuienesSomos = () => {
  return (
    <>
      <BannerPagina pagina="QUIENES_SOMOS" />
      <div className="container">
        <hr className='hrblue' />
        <div className="row text-center">
          <h3 class="mb-0">
            En Renovar Financiera hemos logrado recuperar la cartera de más de <strong>47.000 clientes </strong>
            que se encontraban con sus obligaciones en mora y también hemos otorgado más de
            <strong> 3.000 créditos </strong>
            para cumplir sueños a nivel nacional.
          </h3>
          <footer class="blockquote-footer">Según datos internos en
            <cite title="Source Title">Sistema de información de Renovar Financiera</cite>
          </footer>

        </div>
        <hr className='hrblue' />

        <div className="row justify-content-md-center mb-5">
          <CardAnimated
            title="Misión"
            subtitle="Contribuimos a mejorar la vida crediticia de nuestros clientes a través de
            productos y servicios con soluciones flexibles e innovadoras,
            aportando a su desarrollo económico y social."
            buttonText=""
            buttonUrl=""
            imageUrl={imgMision}
            fixedHeight={180}
          />
          <CardAnimated
            title="Visión"
            subtitle="Para el 2025 ser el vehículo que contribuyó a apoyar la financiación
            y la recuperación económica de más de 41.000 proyectos de vida."
            buttonText=""
            buttonUrl=""
            imageUrl={imgVision}
            fixedHeight={182}
          />

        </div>

        <div className="row text-center my-5">
          <h2><strong>Valores</strong></h2>
          <hr className='hrblue' />
        </div>
        <div className="row justify-content-md-center mb-5">
          <ProcessStep
            imageUrl={imgFelicidad}
            title="Felicidad"
          />
          <ProcessStep
            imageUrl={imgInnovacion}
            title="Innovación"
          />
          <ProcessStep
            imageUrl={imgTransparencia}
            title="Transparencia"
          />
        </div>
        <div className="row justify-content-md-center">
          <ProcessStep
            imageUrl={imgCompromiso}
            title="Compromiso"
          />
          <ProcessStep
            imageUrl={imgTrabajoEquipo}
            title="Trabajo en equipo"
          />
          <ProcessStep
            imageUrl={imgIgualdad}
            title="Igualdad"
          />
        </div>

        <div className="row">
          <div className="row text-center my-5">
            <h2><strong>Conoce nuestras redes</strong></h2>
            <hr className='hrblue' />
          </div>
          <div className="row justify-content-md-center">
            <div className="col-md-4">
              <a href="https://www.instagram.com/renovarfinanciera/?hl=es-la " target='_blank' rel='noreferrer'>
                <ProcessStep
                  imageUrl={imgInstagran}
                  title="@Renovarfinanciera "
                  columns={0}
                />
              </a>

            </div>
            <div className="col-md-4">
              <a href="https://www.facebook.com/RenovarFinancieraCol " target='_blank' rel='noreferrer'>
                <ProcessStep
                  imageUrl={imgFacebook}
                  title="@Renovarfinancieracol"
                  columns={0}
                />
              </a>

            </div>
          </div>
        </div>
        <div className="row justify-content-md-center">

          <div className="col-md-4 gx-5">
            <div className="row text-center my-5">
              <h2><strong>Trabaja con Nosotros</strong></h2>
              <hr className='hrblue' />
            </div>
            <div className="row text-center">

              <div className="row justify-content-md-center">
                <div className="col-md-8 text-center">
                  <img src={imgTrabajeConNosotros} className="img-fluid" alt='' />
                </div>
              </div>
              <hr className='hrblue' />
              <div className="row justify-content-md-center">
                <a href="/trabajeconnosotros" className='btn btn-secondary'>
                  <h4>¡Haz parte de nuestra empresa!</h4>
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default QuienesSomos