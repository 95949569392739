import React from "react";
import Carousel from "react-bootstrap/Carousel";
import PseButton from "../components/PseButton";
import WhatsappButton from "../components/WhatsappButton";
import useFetch from "../hooks/useFetch";
import Fade from "react-reveal/Fade";
import CardAnimated from "../components/CardAnimated";
import Testimonio from "../components/Testimonio";

// images
import logopse from "../components/img/pse1.png";
// import imgSolucionaDeuda from "./img/soluciona_tudeuda.png";
import imgRecuperaCartera from "./img/recupera_cartera.png";
import imgEresDeudor from "./img/eres_deudor.png";
// import imgSolucionesCobran from "./img/soluciones_cobranzas.png";
import imgMicrocredito from "./img/microcredito.jpg";
import imgLibranza from "./img/libranza.jpg";
import imgConsumo from "./img/consumo.jpg";
import imgOtrosProductos from "./img/otros_productos.jpg";
import imgCursosEducacionFinan from "./img/cursos_educacionfinan.jpg";
import imgCursosEmprendedores from "./img/cursos_emprendedores.jpg";
import imgContactenos from "./img/contactenos1.png";
import { ChatbotButton } from "../components/ChatbotButton";
import "./css/Inicio.css"
import { CardsInicio } from "../components/CardsInicio";
import { useResolution } from "../hooks/useResolution";


const { REACT_APP_CMS_URL } = process.env;

const Inicio = () => {
  const { resolution } = useResolution();
  const {
    dataCms: dataBanner,
    errorCms: errorBanner,
    loadingCms: loadingBanner,
  } = useFetch(REACT_APP_CMS_URL + "banner/inicio", "CMS", "");

  if (loadingBanner)
    return (
      <div className="row text-center">
        <h3>Loading ....</h3>
      </div>
    );

  if (errorBanner)
    return (
      <div className="row text-center">
        Lo sentimos. Hay un problema cargando la pagina. Comuniquese con su
        administrador del sistema
      </div>
    );
    
  return (
    <>
      <div className="row">
        <Carousel variant="dark"
          className="banner_item"
        >
          {dataBanner.map((banner) => {
            let alignBannerText = "";
            let alignment = banner.Alignment.toUpperCase();
            alignment === "RIGHT"
              ? (alignBannerText = "text-end ")
              : (alignBannerText = "text-start ");

            return (
              <Carousel.Item key={banner.Page}
                className="banner_item"
              >
                <img
                  className="image_item"
                  src={resolution > 769 ? banner.Banner : dataBanner[0].Banner}
                  alt="slide"
                />
                <Carousel.Caption
                  className="banner_item"
                >
                  {alignment === "LEFT" ? (
                    <div className="row">
                      <div className="col-md-6">
                        <h1 className={`subtitle_margin ${alignBannerText}`} >{banner.Title}</h1>
                        <hr className="hrblue" />
                        <p className={alignBannerText + " fs-4"}>
                          {banner.Subtitle}
                        </p>
                        {banner.ButtonText !== null &&
                          <a
                            href={banner.ButtonUrl}
                            className="btn btn-secondary fs-5"
                          >
                            {banner.ButtonText}
                          </a>
                        }
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-md-6"></div>
                      <div className="col-md-6">
                        <h1 className={`subtitle_margin ${alignBannerText}`} >{banner.Title}</h1>
                        <hr className="hrblue" />
                        <p className={alignBannerText + " fs-4"}>
                          {banner.Subtitle}
                        </p>
                        {banner.ButtonText !== null &&
                          <a
                            href={banner.ButtonUrl}
                            className="btn btn-secondary fs-5"
                          >
                            {banner.ButtonText}
                          </a>
                        }
                      </div>
                    </div>
                  )}
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
      <div className="container my-4">
        <div className="row text-center my-4">
          <h2>Obtén recursos</h2>
        </div>
        <div className="row justify-content-center d-flex align-items-stretch">
          <CardAnimated
            title="Microcrédito"
            subtitle="Nuevas oportunidades para hacer crecer tu negocio"
            buttonText="Solicita tu crédito"
            buttonUrl="/microcredito"
            imageUrl={imgMicrocredito}
          />
          <CardAnimated
            title="Libranza"
            subtitle="Accede a créditos de libranza con nuestros bancos aliados"
            buttonText="Solicita tu crédito"
            buttonUrl="/libranza"
            imageUrl={imgLibranza}
          />
          <CardAnimated
            title="Consumo"
            subtitle="Cumple tus metas con nosotros"
            buttonText="Solicita tu crédito"
            buttonUrl="/consumo"
            imageUrl={imgConsumo}
          />
          <CardAnimated
            title="Otros productos"
            subtitle="Solicítalos aquí rápido y fácil"
            buttonText="Solicita tu crédito"
            buttonUrl="/otrosproductos"
            imageUrl={imgOtrosProductos}
          />
        </div>
        <CardsInicio imgEresDeudor={imgEresDeudor} imgRecuperaCartera={imgRecuperaCartera} />
        <div className="row my-5">
          <Fade left>
            <div className="col-md-5 my-3 text-center ">
              <div className="card">
                <img
                  src={imgCursosEducacionFinan}
                  className="card-img-top"
                  alt="Educacion financiera"
                />
                <div className="card-body">
                  <h5 className="card-title">Curso de educación financiera</h5>
                  <hr className="hrblue" />
                  <p className="card-text">
                    Te brindaremos recursos educativos que enriquezcan tu vida,
                    por eso, ponemos a tu disposición el curso Educación
                    Financiera Gratuito de Edutin Academy
                  </p>
                  <a
                    href="https://edutin.com/curso-de-educacion-financiera-4325"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-outline-secondary"
                  >
                    Mas información
                  </a>
                </div>
              </div>
            </div>
          </Fade>
          <div className="col-md-2"></div>
          <Fade right>
            <div className="col-md-5 my-3 text-center ">
              <div className="card">
                <img
                  src={imgCursosEmprendedores}
                  className="card-img-top"
                  alt="Emprendedores"
                />
                <div className="card-body">
                  <h5 className="card-title">Curso para Emprendedores</h5>
                  <hr className="hrblue" />
                  <p className="card-text">
                    Nuestro portal de emprendedores te permitirá tener todas las
                    herramientas para mejorar tu negocio y tener mucho éxito con
                    tus productos.l
                  </p>
                  <a
                    href="https://edutin.com/curso-de-emprendimiento-4214"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-outline-secondary"
                  >
                    Mas información
                  </a>
                </div>
              </div>
            </div>
          </Fade>
        </div>

        <Testimonio />

        <div className="row">
          <div className="col-6 text-left d-flex align-items-center">
            <div className="row">
              <div className="col-md-6 text-center">
                <div className="col-md-6">
                  <img src={imgContactenos} className="img-fluid" alt="" />
                </div>
                <div className="col-md-6">
                  <a href="/contactenos" className="btn btn-secondary">
                    <h4>Contáctanos</h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-2"> </div>
          <div className="col-4">
            <div className="row">
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <a className="navbar-brand" href="/mediospago">
                  <img
                    src={logopse}
                    width="100"
                    height="100"
                    className="d-inline-block align-top py-0"
                    alt=""
                  />
                </a>
              </div>
              <div className="col-md-6 d-flex align-items-center ">
                <a className="btn btn-secondary" href="/mediospago">
                  <h4>Paga aquí</h4>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WhatsappButton />
      <PseButton />
      <ChatbotButton />
    </>
  );
};

export default Inicio;
