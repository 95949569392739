import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from './img/logoRenovar1.png'
import './css/components.css'

const BarraMenu = () => {
    return (
        <Navbar bg="light" expand="lg" sticky="top" className='py-0 my-0'>
            <Container>
                <Navbar.Brand href='/'>
                    <img src={logo} width="196" height="110"
                        className="d-inline-block align-top py-0" alt="Logo Renovar" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto fs-6">
                        <Nav.Link href="/" className='fw-bold'>Inicio</Nav.Link>        
                        <NavDropdown title="Quiénes Somos" id="basic-nav-dropdown" className='fw-bold'>
                            <NavDropdown.Item href="quienessomos">Nuestra Empresa</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="trabajeconnosotros">Trabaja Con Nosotros</NavDropdown.Item>
                        </NavDropdown>  
                        <NavDropdown title="Obtén recursos" id="basic-nav-dropdown" className='fw-bold'>
                            <NavDropdown.Item href="microcredito">Microcrédito</NavDropdown.Item>
                            <NavDropdown.Item href="libranza">Libranza</NavDropdown.Item>
                            <NavDropdown.Item href="consumo">Consumo</NavDropdown.Item>
                            <NavDropdown.Item href="otrosproductos">Otros Productos</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="solucionescobranza">Recupera tu cartera</NavDropdown.Item>
                        </NavDropdown>              
                        <Nav.Link href="mediospago" className='fw-bold'>Medios de Pago</Nav.Link>
                        <Nav.Link href="sobretudeuda" className='fw-bold'>Conoce tu deuda</Nav.Link>
                        <Nav.Link href="contactenos" className='fw-bold'>Contáctanos</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>

        </Navbar>
    )
}

export default BarraMenu